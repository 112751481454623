import $ from 'jquery';

jQuery(window).on('load', function(){
  // init Masonry
  var $grid = $('.grid').masonry({
    itemSelector: '.grid-item',
    percentPosition: true,
    columnWidth: '.grid-sizer',
  });
  // layout Masonry after each image loads
  $grid.imagesLoaded().progress( function() {
    $grid.masonry();
  });

  $('.grid-isotope').isotope({
    // options
  });
  // filter items on button click
  $('.filter-button-group').on( 'click', 'button', function() {
    var filterValue = $(this).attr('data-filter');
    $grid.isotope({ filter: filterValue });

    if($(".filter-active")) {
      $(".filter-active").removeClass("filter-active");
      $(this).addClass("filter-active");
    } else {
      $(this).addClass("filter-active");
    }
  });
});
